.app_form {
    height: max-content;
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding-top: .8rem;
    padding-bottom: 2rem;
    background: rgb(225, 225, 225);
    zoom: 90%;
}

@media (max-width: 900px) {
    .app_form { 
        width: 100%;
    }
}

.firstTable {
    width:100%;
}

.editTable {
    width:100%;
}

.firstCol {
    width:15%;
}

.secondCol {
    width:30%;
}

.thirdCol {
    width:20%;
}

.fourthCol {
    height:50px;
    min-width: 300px;
    width:35%;
}


.main_table {
    overflow-x: auto;
    font-size: 1.2rem;
    width: 97%;
    margin: 0 auto;
    border: 2px solid gray;
    padding: .8rem 2rem;
}

select {
    font-size: 1.2rem;
    padding: .2rem .5rem;
}

.remark {
    font-size: 1.2rem;
    width: 100%;
    height:100%;
    box-sizing: border-box;
}

.mainTable {
    /* width:100%; */
    padding: .8rem 2rem;
    border: 1px solid rgba(139, 137, 137, 0.7);
    text-align: center;
    font-size: 1.4rem;
}

.headsTable {
    width: max-content;
    font-weight: 600;
    color: #b00202;
    /* writing-mode: vertical-lr; */
}
