.control_btns {
    display: flex;
    flex-direction: row;
    height: max-content;
    width: 50%;
    background-color: rgb(225, 225, 225);
    margin: 0 auto;
    margin-top: 2rem;
    padding: 2rem;
    justify-content: space-around;
}

/* .abc {
  scroll-behavior: smooth;
} */

.full {
    height: max-content;
    width: 80%;
    margin: 0 auto;
    margin-top: 4rem;
    padding-top: 2rem;
    background-color: rgb(225, 225, 225);
}

.full-2 {
    height: max-content;
    width: 80%;
    margin: 0 auto;
    margin-top: 0rem;
    padding-top: 2rem;
    background-color: rgb(225, 225, 225);
}

.emps {

    margin-top: 10rem;
    background: red;
}

@keyframes loadingAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loadingScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loadingSpinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #b00202;
    border-radius: 50%;
    animation: loadingAnimation 1s infinite linear;
  }
  