.form {
    width: 100%;
    max-width: 600px;
    font-size: 1.2rem;
    margin: 0 auto;
    /* margin-top: 20%; */
    padding: 4rem;
    font-weight: 500;
    justify-content: center;
}

/* .flexer {
    display:flex;
}

@media (max-width:750px) {
    .flexer {
      flex-direction: column;
    }
} */
.label {
    width: 50%;
    padding: 1.2rem 0;
}



#id_input,
#pass_input {
    cursor: pointer;
    width: 100%;
    padding: 0.4rem .6rem;
    border: 2px solid #b00202ac;
    color: #b00202;
    font-size: 1.2rem;
    background: rgb(225, 225, 225);
    font-weight: 500;
    margin: 0 auto;
    padding: 4rem;
    font-weight: 500;
    justify-content: center;
}

label {
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;   
}

input {
    height: 45px;
    width : 60%;
    margin : 0px 10px;
}

.LoginButton {
    margin-top: 2.5rem;
    border: #b00202 2px solid;
    /* color: rgb(225, 225, 225); */
    font-weight: 500;
    width: 100%;
    max-width: 100px;
    align-items: center;
    align-content: center;
    align-self: center;
    font-size: 1.2rem;
    padding: .5rem 2rem;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    box-sizing: border-box;
}

.textSecond {
    width: 100%;
    margin: 0 auto;
    color: #b00202;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 2rem;
}

.forgotPass {
    font-size: 1rem;
    color: #b00202;
    margin: 0 auto;
    margin-top: .5rem;
    width: max-content;
    cursor: pointer;
}

.invalidCred {
    font-size: 1rem;
    width: max-content;
    color: #b00202;
    column-span: 2;
}

#emailInput,
#passInput {
    cursor: pointer;
    
    border: 2px solid #b00202ac;
    color: #b00202;
    font-size: 1.2rem;
    background: rgb(225, 225, 225);
    font-weight: 500;
}

/* legend type input form */
fieldset {
    border: none;
    font-size: 1.4rem;
}

fieldset > input{
    width: 100%;
    margin: 0;
    border: none;
}

fieldset:last-of-type 
{
    margin-bottom: 1rem;
}

