.btn {
    margin-left: 0.3rem;
    margin-top: 1rem;
} 

.addComment {
    border-color: black;
    border-width: 2px;
    padding: 0.3rem;
    border-radius: 5px;
    background-color: rgb(210, 210, 210);
    font-weight: 600;
}