.loadbtn {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 2rem;
    padding-top: 2rem;

}

@keyframes loadingAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loadingScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loadingSpinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #b00202;
    border-radius: 50%;
    animation: loadingAnimation 1s infinite linear;
  }
  