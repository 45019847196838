.comment {
    max-width: 100%;
}

.comment-preview {
    border-width: 0.2rem;
    border-color: rgb(53, 53, 53);
    border-radius: 5px;
    margin-bottom: 0.5rem;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    padding: 0.2 0.2 0.0 0.2;
    background-color: rgb(210, 210, 210);
}

.headView {
    color:rgb(212, 61, 61);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 300;
    font-style: italic;
    font-size: 1.6rem;
}

.dateView {
    display: inline;
    color:rgb(104, 104, 245);
    font-weight: 200;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 1.2rem;
}

.textView {
    display: inline;
    font-size: 1.2rem;
    font-weight: 200;   
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;   
    color: rgb(43, 43, 43);
}