.login {
    height: max-content;
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    background-color: #cfcece;
    box-shadow: #9292924f 10px 10px;
    box-sizing: border-box;
    border-radius: 20px;
}

@media (max-width: 900px) {
    .login {
        width: 90%;
    }
}

/* @media (max-height: 750px) {
    .login {
        overflow-y:auto;
    }    
} */

@media (max-height: 650px) {
    .login{
        position: absolute;
        margin-top:60px;
    }
}