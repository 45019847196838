.Inbtn {
    border: #b00202 2px solid;
    /* color: rgb(225, 225, 225); */
    margin-top: 1rem;
    font-weight: 500;
    width: max-content;
    font-size: 1.2rem;
    padding: .5rem 2rem;
    cursor: pointer;
    /* background: rgba(255, 255, 255);  */
    /* margin: 0rem auto; */
}