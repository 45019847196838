.NewSupButton {
    border: #b00202 2px solid;
    /* color: rgb(225, 225, 225); */
    font-weight: 500;
    width: max-content;
    font-size: 1.2rem;
    padding: .5rem 2rem;
    cursor: pointer;
    background: #d9d9d9;
    margin: 1rem;
    text-align: center;
}

@media (max-width: 850px) {
    .NewSupButton {
    padding: .5rem 1rem;
    font-size: 1rem;
    }
  }