 .dataTable {
    width: max-content;
    width:100%;
    margin: 0 auto;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    padding: .8rem 2rem;
}
/*
.editTable {
    width:100%;
}

.firstCol {
    width:15%;
}

.secondCol {
    width:30%;
}

.thirdCol {
    width:20%;
}

.fourthCol {
    min-width: 300px;
    width:35%;
}

.btn {
    margin: 0 auto;
    width: max-content;
    margin-top: 2rem;
}

.remark {
    font-size: 1.2rem;
    width: 100%;
    padding: .8rem 2rem;
    height: auto;
    resize: both;
    overflow-wrap: break-word;
    word-break: break-all;
}

@media (max-width: 900px) {
    .app_form { 
        width: 100%;
    }
    .main_table {
        width: 100%;
    }
} */

.app_form {
    height: max-content;
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding-top: .8rem;
    padding-bottom: 2rem;
    background: rgb(225, 225, 225);
    zoom: 90%;
}

@media (max-width: 900px) {
    .app_form { 
        width: 100%;
    }
}

.firstTable {
    width:100%;
}

.editTable {
    width:100%;
}

.firstCol {
    width:15%;
}

.secondCol {
    width:30%;
}

.thirdCol {
    width:20%;
}

.fourthCol {
    min-width: 300px;
    width:35%;
}


.main_table {
    overflow-x: auto;
    font-size: 1.2rem;
    width: 97%;
    margin: 0 auto;
    border: 2px solid gray;
    padding: .8rem 2rem;
}

select {
    font-size: 1.2rem;
    padding: .2rem .5rem;
}

.remark {
    font-size: 1.2rem;
    /* width: 100%; */
    height:80px;
    box-sizing: border-box;
    word-break: break-word;
    white-space: pre-wrap;
}

.mainTable {
    padding: .8rem 2rem;
    border: 1px solid rgba(139, 137, 137, 0.7);
    text-align: center;
    font-size: 1.4rem;
}

.headsTable {
    width: max-content;
    font-weight: 600;
    color: #b00202;
}

.commentSection{
    min-height: 50rem;
    border-width: 0.2rem;
    border-color: #b00202;   
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 10px;
    padding-right: 0.5rem;
}

.headsComments {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    text-align: center;
    font-size: 2.3rem;
    font-weight: 600;
    color: #b00202;
}

