.emptable {
    margin: 0 auto;
    width: max-content;
    font-size: 1.2rem;
    padding: .4rem 2rem;
    text-align: center;
    /* padding: 1rem 8rem; */
}

.empheads {
    font-weight: 600;
    color: #b00202;
}

.empheads,
.datas {
    padding: .8rem 4rem;
}

@media (max-width:1225px) {
    .datas, .empheads {
        padding: .8rem 1.2rem;
    }
}

@media (max-width:1150px) {
    .datas, .empheads {
        padding: .8rem 0.8rem;
    }
}
@media (max-width:900px) {
    .datas, .empheads {
        padding: .8rem 0.6rem;
    }
}

.textShrink {
    width: max-content;
}



.datas {
    cursor: pointer;
}

.data:hover {
    background-color: rgb(209, 209, 209);
}

.alertEmp {
    font-size: 1.2rem;
    width: max-content;
    margin: 2rem auto;
}

@media (max-width:800px) {
    .alertEmp {
        font-size: 0.8rem;
    }
}