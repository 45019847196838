.dataForm {
    width: max-content;
    height: max-content;
    background: #cfcece;
    padding: 2rem 4rem;
    font-size: 1.2rem;
    margin: 6rem auto;
    /* overflow:hidden; */
}

@media (max-width: 650px) {
    .dataForm{
        padding: 2rem 1rem;
        padding-right: 150px;
        zoom:70%;
    }
}

.table {
    margin: 0 auto;
}

.newSup {
    cursor: pointer;
    width: 20rem;
    padding: 0.4rem .6rem;
    border: 2px solid #b00202ac;
    color: #b00202;
    font-size: 1.2rem;
    background: rgb(225, 225, 225);
    font-weight: 500;
    margin-bottom: .6rem;
}

.newSupLabel {
    width: 55%;
    padding: 1.2rem 2rem;
    margin-right: 2rem;
}
