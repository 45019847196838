.ResetButton {

    margin-top: 2.5rem;
    margin-bottom: .7rem;
    border: #b00202 2px solid;
    /* color: rgb(225, 225, 225); */
    font-weight: 500;
    width: max-content;
    font-size: 1.2rem;
    padding: .5rem 2rem;
    margin-left: auto;
    /* margin-right: 1rem; */
    cursor: pointer;
}

label {
    cursor: pointer;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}



#resetEmail {
    cursor: pointer;
    padding: 0.4rem .6rem;
    border: 2px solid #b00202ac;
    color: #b00202;
    font-size: 1.2rem;
    background: rgb(225, 225, 225);
    font-weight: 500;
}