@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

.heading {
    font-family: Poppins;
    font-weight: 800;
    color: #b00202;
    width: max-content;
    display: flex;
    margin: 0 auto;

}

.b2,
.amp {
    margin-left: -1.1rem;
}

.b1,
.b2 {
    z-index: 10;
}

.amp {
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #D9D9D9;
    z-index: 12;
}