.NewEmpBtn {
    border: #b00202 2px solid;
    /* color: rgb(225, 225, 225); */
    font-weight: 500;
    width: max-content;
    font-size: 1.2rem;
    padding: .5rem 2rem;
    cursor: pointer;
} 

.textCenter {
    justify-content: center;
}